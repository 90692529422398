<template>
	<div class="map">
		<l-map :zoom="zoom" :center="center" :options="mapOptions">
			<l-tile-layer :url="url" />
			<l-marker
				v-for="(organisation, index) in organisations"
				:key="index"
				:ref="`marker-${index}`"
				:lat-lng="organisation.coordinates"
			>
				<l-icon>
					<img src="@/assets/imgs/icons/circle.svg" alt="" />
				</l-icon>
				<l-popup>
					<div class="map__popup" @click="showOrganisation(organisation)">
						<img
							class="map__popup__img"
							:src="organisation.image"
							:alt="organisation.slug"
							v-if="organisation.image"
						/>
						<VLink
							classes="map__popup__link"
							@click.stop.prevent="showOrganisation(organisation)"
						>
							{{ organisation.name }}
						</VLink>
					</div>
				</l-popup>
			</l-marker>
		</l-map>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import { latLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import {
	LTileLayer,
	LTooltip,
	LMarker,
	LPopup,
	LIcon,
	LMap
} from 'vue2-leaflet';

export default {
	name: 'MapView',
	components: {
		LTileLayer,
		LTooltip,
		LMarker,
		LPopup,
		LIcon,
		LMap
	},
	data() {
		return {
			zoom: 4,
			center: latLng(8.7832, 34.5085),
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			currentCenter: latLng(1.286389, 36.817223),
			mapOptions: {
				zoomSnap: 0.5
			}
		};
	},
	computed: {
		...mapState({
			organisations: state => state.community.organisations
		})
	},
	methods: {
		showOrganisation(organisation) {
			this.$router.push({
				name: 'organisation-profile',
				params: { slug: organisation.slug }
			});
		}
	}
};
</script>
