<template>
	<div>
		<div class="page-section">
			<div class="page-section__inner gr gr--wrap">
				<div class="col col--1@tablet hide show@tablet"></div>
				<div class="col col--12 col--10@tablet">
					<div class="eng-tab-section">
						<div class="eng-tab-section__tab">
							<div
								class="eng-tab-section__tab__item cursor-pointer"
								:class="{ active: tab === 'map' }"
								@click="setTab('map')"
							>
								<div class="subtitle subtitle--semibold">
									Map view
								</div>
							</div>
							<div
								class="eng-tab-section__tab__item cursor-pointer"
								:class="{ active: tab === 'list' }"
								@click="setTab('list')"
							>
								<div class="subtitle subtitle--semibold">
									List view
								</div>
							</div>
						</div>
						<div class="eng-tab-section__content" id="tab-content">
							<div>
								<div class="filter filter--map no-margin-t">
									<div class="filter__inner">
										<div class="filter__inner__content">
											<VText size="big" weight="bold">
												Filter by country
											</VText>
											<CountriesMultiselect
												v-model="filters.countries"
												:queryParams="{ global: 1 }"
												class="form__multiselect--beige"
											/>
										</div>
										<div class="filter__inner__content">
											<VText size="big" weight="bold">
												Filter by specialism
											</VText>
											<SpecialismsMultiselect
												v-model="filters.specialisms"
												class="form__multiselect--beige"
											/>
										</div>
									</div>
								</div>

								<MapView v-if="tab === 'map'" key="map" />
								<ListView v-else key="list" />
							</div>
						</div>
					</div>
				</div>
				<div class="col col--1@tablet hide show@tablet"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { FETCH_ORGANISATIONS } from '@/store/actions.type';
import { SET_COUNTRIES } from '../../store/mutations.type';

import content from '@/mixins/content';
import MapView from '@/pages/CommunityPage/MapView';
import ListView from '@/pages/CommunityPage/ListView';

export default {
	name: 'CommunityPage',
	mixins: [content],
	components: {
		MapView,
		ListView
	},
	computed: {
		...mapGetters(['isAuthenticated']),
		...mapState({
			organisations: state => state.community.organisations
		})
	},
	data() {
		return {
			expand: null,
			tab: 'map',
			filters: {
				countries: [],
				specialisms: []
			}
		};
	},
	destroyed() {
		this.$store.commit(SET_COUNTRIES, []);
	},
	created() {
		this.fetchContent('registered-organisations');
	},
	async mounted() {
		await this.fetchData();

		if (!this.isAuthenticated) {
			this.$store.commit('setActiveModal', 'FreeAccountModal');
		}
	},
	watch: {
		filters: {
			handler: 'fetchData',
			immediate: true,
			deep: true
		}
	},
	methods: {
		async fetchData() {
			if (!this.isAuthenticated) return;

			const countries = this.filters.countries.map(country => country.id);
			const specialisms = this.filters.specialisms.map(
				specialism => specialism.id
			);

			await this.$store.dispatch(FETCH_ORGANISATIONS, {
				countries: countries,
				specialisms: specialisms
			});
		},
		setTab(item) {
			this.tab = item;
		}
	}
};
</script>

<style scoped>
/deep/ .multiselect__placeholder {
	margin-bottom: 10px;
}

/deep/ .multiselect__arrow {
	position: absolute;
	right: 15px;
	top: 15px;
}
</style>
